<template>
  <div class="register">
    <div class="register-container">
      <div class="register-logo">
        <div class="logo-container">
          <p>Welcome to</p>
          <img src="../../assets/img/register-logo.png" style="width: 400px">
        </div>
      </div>
      <div v-show="!isLogin && isForgetPassword==false" class="register-form-container animated fadeInUp">
        <div class="register-form">
          <h3>快速开始7天免费试用</h3>
          <div class="main-register">
            <el-form ref="form" :rules="rules" :model="form">
              <el-form-item prop="email">
                <el-input id="email" v-model.trim="form.email">
                  <template slot="prefix">
                    <label for="email" :class="['input-label',{'hasV': form.email}]">邮箱</label>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input id="password" v-model.trim="form.password" type="password" show-password>
                  <template slot="prefix">
                    <label for="password" :class="['input-label',{'hasV': form.password}]">密码</label>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="phone">
                <el-input id="phone" v-model.trim="form.phone">
                  <template slot="prefix">
                    <label for="phone" :class="['input-label',{'hasV': form.phone}]">电话号码</label>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="code">
                <el-input id="code" v-model.trim="form.code">
                  <template slot="prefix">
                    <label for="code" :class="['input-label',{'hasV': form.code}]">验证码</label>
                  </template>
                  <template slot="append">
                    <span v-show="showSendCode" class="sendCode" @click="sendCode"> 发送验证码 </span>
                    <span v-show="!showSendCode" class="sendCode st">{{ '重新发送 ' + count }} s</span>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitForm('form')">
                  注册
                </el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="privacy-policy">
            <el-checkbox v-model="privacy" />
            <div class="privacy-container">
              注册表示你已同意
              <a href="/treaty/contract"  target="_blank" >平台合同</a>
              、
              <a href="/treaty/userAgreement"  target="_blank">用户协议</a>
              、
              <a href="/treaty/privacyPolicy" target="_blank">隐私政策</a>
            </div>
          </div>
          <div v-show="policyError" class="privacy-policy-error">
            请先阅读并同意用户协议
          </div>
          <div class="login-bottom">
            已有账号，
            <span @click="gotoLogin()">
              直接登录
            </span>
          </div>
        </div>
      </div>
      <!-- 登录 -->
      <div v-show="isLogin && isForgetPassword==false" class="register-form-container animated fadeInUp">
        <div class="register-form">
          <h3>登录商店</h3>
          <div class="main-register">
            <el-form ref="loginform" :rules="loginRules" :model="form">
              <el-form-item prop="username">
                <el-input id="loginPhone" v-model.trim="form.username">
                  <template slot="prefix">
                    <label for="loginPhone" :class="['input-label',{'hasV': form.username}]">手机</label>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input id="loginPassword" v-model.trim="form.password" type="password" show-password>
                  <template slot="prefix">
                    <label for="loginPassword" :class="['input-label',{'hasV': form.password}]">密码</label>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitLoginForm('loginform')">
                  登录
                </el-button>
              </el-form-item>
            </el-form>
          </div>
          <div v-show="policyError" class="privacy-policy-error">
            请先阅读并同意用户协议
          </div>
          <div class="forget-bottom" @click="isForgetPassword=true">
            <span>忘记密码？</span>
          </div>
          <div class="login-bottom">
            没有账号，去
            <span @click="gotoRegister()">
              注册
            </span>
          </div>
        </div>
      </div>
      <!-- 忘记密码 -->
      <div v-show="isForgetPassword" class="register-form-container animated fadeInUp">
        <div class="register-form">
          <h3>重置密码</h3>
          <div class="main-register">
            <el-form ref="forgetform" :rules="forgetRules" :model="form">
              <el-form-item prop="phone">
                <el-input id="loginPhone" v-model.trim="form.phone">
                  <template slot="prefix">
                    <label for="loginPhone" :class="['input-label',{'hasV': form.phone}]">手机</label>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="code">
                <el-input id="code" v-model.trim="form.code">
                  <template slot="prefix">
                    <label for="code" :class="['input-label',{'hasV': form.code}]">验证码</label>
                  </template>
                  <template slot="append">
                    <span v-show="showSendCode" class="sendCode" @click="sendCode"> 发送验证码 </span>
                    <span v-show="!showSendCode" class="sendCode st">{{ '重新发送 ' + count }} s</span>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input id="loginPassword" v-model.trim="form.password" type="password" show-password>
                  <template slot="prefix">
                    <label for="loginPassword" :class="['input-label',{'hasV': form.password}]">新密码</label>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitSetPasswordForm('forgetform')">
                  重置
                </el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="forget-bottom" @click="gotoRegister()">
            <span>直接注册</span>
          </div>
          <div class="login-bottom">
            想起密码了，去
            <span @click="gotoLogin()">
              登录
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { login, register, sendVerificationCode, shopInfo, updatePassWord } from '@/api/register'
import { isvalidPhone, validatAlphabets, validatEmail } from '@/utils/validate'
import { Message } from 'element-ui'
import { setToken, setusernameToken } from '@/utils/cookie'

export default {
  name: 'Register',
  data() {
    const validEmail = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入电子邮箱'))
      } else if (!validatEmail(value)) {
        callback(new Error('请输入正确的电子邮箱'))
      } else {
        callback()
      }
    }
    const validPassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入密码'))
      } else if (value.length < 8 && value.length > 20) {
        callback(new Error('请设置8-20位密码，需要包含大小写'))
      } else if (!validatAlphabets(value)) {
        callback(new Error('请设置8-20位密码，需要包含大小写'))
      } else {
        callback()
      }
    }
    const validPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入电话号码'))
      } else if (!isvalidPhone(value)) {
        callback(new Error('请输入正确的11位手机号码'))
      } else {
        callback()
      }
    }
    const validCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入验证码'))
      } else if (value.length < 4) {
        callback(new Error('请输入4位验证码'))
      } else {
        callback()
      }
    }

    return {
      // 忘记密码
      isForgetPassword: false,
      // 注册/登录
      isLogin: false,
      code: '',
      policyError: false,
      privacy: false,
      showSendCode: true,
      timer: null,
      count: '',
      form: {
        email: '',
        password: '',
        phone: '',
        code: ''
      },
      rules: {
        email: [
          { required: true, trigger: 'blur', validator: validEmail }
        ],
        password: [
          { required: true, trigger: 'blur', validator: validPassword }
        ],
        phone: [
          { required: true, trigger: 'blur', validator: validPhone }
        ],
        code: [
          { required: true, trigger: 'blur', validator: validCode }
        ]
      },
      loginRules: {
        username: [
          { required: true, trigger: 'blur', message: '请输入账号' }
        ],
        password: [
          { required: true, trigger: 'blur', message: '请输入密码' }
        ]
      },
      forgetRules: {
        phone: [
          { required: true, trigger: 'blur', validator: validPhone }
        ],
        code: [
          { required: true, trigger: 'blur', validator: validCode }
        ],
        password: [
          { required: true, trigger: 'blur', message: '请输入密码' }
        ]
      }
    }
  },
  mounted: function() {
    setusernameToken('')
    this.isLogin = this.$route.query.registerType === 'login'
  },
  methods: {
    gotoRegister() {
      this.isLogin = false
      this.isForgetPassword = false
    },
    gotoLogin() {
      this.isLogin = true
      this.isForgetPassword = false
    },
    // 重置密码
    submitSetPasswordForm(formName) {
      const that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          updatePassWord(this.form).then((res) => {
            console.log(res)
            if (res.code === 0) {
              Message.success(`重置成功`)
              that.isLogin = true
              that.isForgetPassword = false
            }
          })
        }
      })
    },
    // 登录
    submitLoginForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          login(this.form).then((res) => {
            if (res.code === 0) {
              // 用户对象
              const user = window.atob(res.data.token.split('.')[1])
              // 用户名
              const username = JSON.parse(user).sub
              // 缓存用户名
              setusernameToken(username)
              // 根据用户缓存token
              setToken(username, res.data.token)

              this.LoginAfter()
            } else {
              Message.error(`${res.msg}`)
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    LoginAfter() {
      shopInfo().then(res => {
        if (res.code === 0) {
          this.$router.push({ name: 'shopManagement' })
        } else {
          this.$router.push({ name: 'shopManagement' })
        }
      })
    },
    // 注册
    submitForm(formName) {
      if (this.privacy) {
        console.log(this.$refs[formName])
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.$route.query.promoteCode) {
              this.form.promoteCode = this.$route.query.promoteCode
            }
            register(this.form).then((res) => {
              if (res.code === 0) {
                this.form.username = this.form.phone
                login(this.form).then((res) => {
                  if (res.code === 0) {
                    // 用户对象
                    const user = window.atob(res.data.token.split('.')[1])
                    // 用户名
                    const username = JSON.parse(user).sub
                    // 缓存用户名
                    setusernameToken(username)
                    // 根据用户缓存token
                    setToken(username, res.data.token)
                    // setToken(res.data.token)
                    this.LoginAfter()
                  } else {
                    Message.error(`${res.msg}`)
                  }
                })
              } else {
                Message.error(`${res.msg}`)
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        this.policyError = true
      }
    },
    validCode(res) {
      if (res.code === 0) {
        this.code = res.data
        const TIME_COUNT = 60
        if (!this.timer) {
          this.count = TIME_COUNT
          this.showSendCode = false
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--
            } else {
              this.showSendCode = true
              clearInterval(this.timer)
              this.timer = null
            }
          }, 1000)
        }
      }
    },
    sendCode() {
      if (!isvalidPhone(this.form.phone)) {
        return
      } else {
        if (this.isForgetPassword) {
          sendVerificationCode(this.form.phone, 1).then((res) => {
            this.validCode(res)
          })
        } else {
          sendVerificationCode(this.form.phone).then((res) => {
            this.validCode(res)
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.privacy-container{
  a:hover {
    color: #0000EE;
    cursor: pointer;
    text-decoration: underline;
  }
}

@media(max-width: 576px) {
  .register-container{
    min-width: 0 !important;
    flex-direction: row-reverse;
  }
  .register-form-container{
    width: 100vw !important;
  }
  .register-logo{
    display: none;
  }
}
.register{
  overflow-y: hidden;
}
.st{
  cursor: not-allowed !important;
}
img{
  vertical-align: middle;
  border-style: none;
}
.register-container{
  display: flex;
  flex-direction: row;
  min-width: 1200px;
  height: 100vh;
  min-height: 680px;
  font-size: 14px;
  line-height: 20px;
}
.register-logo{
  position: relative;
  width: 67.5%;
  background: url(../../assets/img/register-bg.jpg) center / cover no-repeat;
  &:after{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background-color: rgba(36, 40, 51, 0.5);
    content: '';
  }
}
.logo-container{
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  margin-top: -3%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  p{
    margin-bottom: 24px;
    color: #fff;
    font-weight: 300;
    font-size: 32px;
    line-height: 1;
  }
}
.register-form-container{
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}
.register-form{
  width: 80%;
  max-width: 400px;
  h3{
    margin-bottom: 32px;
    color: #262626;
    font-weight: 600;
    font-size: 30px;
    line-height: 42px;
  }
}
.main-register{
  max-width: 400px;
}
.privacy-policy {
  display: flex;
  justify-content: center;
  color: #7a8499;
  font-size: 12px;
  line-height: 16px;
  .el-checkbox{
    margin-right: 11px;
  }
}
.privacy-policy-error{
  margin-top: 5px;
  color: #f86140;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.forget-bottom{
  position: absolute;
  right: 0;
  bottom: 7%;
  left: 0;
  color: #474f5e;
  text-align: center;
  span{
    color: #356dff;
    font-weight: 600;
    cursor: pointer;
  }
}
.login-bottom{
  position: absolute;
  right: 0;
  bottom: 5%;
  left: 0;
  color: #474f5e;
  text-align: center;
  span{
    color: #356dff;
    font-weight: 600;
    cursor: pointer;
  }
}
.el-form-item{
  margin-bottom: 30px;
}
::v-deep .el-input  input{
  height: 52px;
  padding-top: 20px;
  padding-left: 10px;
  box-sizing: border-box;
  vertical-align: bottom;
  span label{
    transition: all .3s;
  }
}
::v-deep .el-input>input:focus + span label{
  transition: all .3s;
  //color: #0364FF;
  transform: scale(0.9) translateY(-35%);
}
::v-deep .hasV{
  //color: #0364FF;
  transform: scale(0.9) translateY(-35%);
}
::v-deep .el-input__prefix{
  line-height: 16px;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  z-index: 2;
  font-size: 14px;
  color: rgba(44,44,44,.6);
  transition: transform .3s linear;
  transform-origin: left;
}
.sendCode{
  color: #1E70FF;
  cursor: pointer;
}
.el-button{
  width: 100%;
}
</style>
